import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const OrderedAlphaList = styled.ol`
  counter-reset: list;
  & > li {
    list-style: none;
    margin-bottom: 1rem;
    &:before {
      content: counter(list, lower-alpha) ') ';
      counter-increment: list;
    }
  }
`;

const PrivacyPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)}>
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Política de privacidad" />

      <Container>
        <BannerContainer title="Política de privacidad" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              aVenezuela, honrando el acuerdo asumido con sus clientes en los
              Términos y Condiciones, cumplirá con la protección de los datos de
              sus clientes basándose en la Política de Privacidad.
            </p>
            <OrderedAlphaList>
              <li>
                La información personal del cliente será tratada de forma
                confidencial y no será revelada a terceros. Una vez registrada
                la información personal del cliente, la misma quedará sujeta a
                los términos de la Política de Privacidad, y excepto en el caso
                de que dicha revelación sea necesaria por imposición de la ley o
                en virtud de un acuerdo o petición emitida por el Regulador
                Fiscal u otra autoridad legal o reguladora competente, deberá
                ser accesible sólo para nosotros, nuestros agentes, o por una
                empresa controlada por nosotros; y será procesada o utilizada
                por nosotros para los propósitos del servicio que brindamos (y
                que el cliente solicita), y de una manera compatible con el
                cumplimiento de nuestra obligación en virtud del Acuerdo. La
                información personal del cliente se archiva en nuestro servidor
                de manera encriptada para proteger su privacidad y brindar la
                mayor seguridad.
              </li>
              <li>
                Información que recopilamos y permisos.
                <br />
                Recopilamos la siguiente información de los clientes de manera
                directa o indirecta:
                <ul>
                  <li>Nombres y apellidos.</li>
                  <li>Correo electrónico.</li>
                  <li>País de residencia.</li>
                  <li>Número de teléfono.</li>
                  <li>Métodos de pago.</li>
                  <li>Dirección particular.</li>
                  <li>Historial de compras en aVenezuela.</li>
                  <li>
                    Contactos telefónicos a los que se envían las recargas.
                  </li>
                  <li>Dirección IP del dispositivo utilizado.</li>
                </ul>
                <p>
                  Esta información personal es necesaria para brindar nuestros
                  servicios y darle una experiencia personalizada a nuestro
                  cliente. Se pedirá al usuario su aceptación de nuestros
                  Términos y Condiciones y de nuestra Política de Privacidad
                  para guardar su información personal y crear su cuenta en
                  aVenezuela. El cliente puede crear sus propios contactos
                  dentro de la aplicación de aVenezuela completando un
                  formulario y de manera opcional puede auxiliarse de la lista
                  de contactos que ya tiene almacenada en su teléfono, tableta u
                  otro dispositivo. La aplicación móvil de aVenezuela pedirá
                  permiso al cliente para acceder a esos contactos que ya están
                  previamente almacenados en el dispositivo. En el servidor de
                  aVenezuela sólo quedarán almacenados los contactos creados
                  por el cliente dentro de la aplicación. Los contactos que
                  previamente estaban registrados en el dispositivo del cliente
                  no serán almacenados en el servidor de aVenezuela.
                </p>
              </li>
              <li>
                Uso de la información personal del cliente.
                <br />
                Podemos usar esta información para lo siguiente:
                <ul>
                  <li>
                    Para que el cliente tenga sus contactos frecuentes, los que
                    ha recargado presentados de una manera más accesible,
                    independientemente de la plataforma que esté utilizando,
                    sitio web o aplicación, para hacer una recarga.
                  </li>
                  <li>
                    Para brindar al cliente información sobre productos o
                    servicios que le puedan interesar.
                  </li>
                  <li>
                    Para notificar al cliente acerca de cualquier cambio en
                    nuestro sitio web/aplicación o en nuestros servicios.
                  </li>
                  <li>
                    Para asegurarnos que el contenido de nuestro sitio
                    web/aplicación sea presentado al cliente de la manera más
                    efectiva.
                  </li>
                  <li>Para brindar la mejor atención al cliente.</li>
                  <li>Para propósitos de seguridad y prevención de fraude.</li>
                  <li>Para análisis de marketing.</li>
                </ul>
              </li>
              <li>
                Eliminar la información personal del cliente de nuestro sistema.
                <br />
                El cliente puede eliminar su cuenta del sistema de aVenezuela
                cuando lo desee. Para ello debe comunicar su solicitud de
                eliminación de cuenta al equipo de atención al cliente a través
                del correo electrónico soporte@avenezuela.com o llamando al
                teléfono +1(305)376-7119. El cliente deberá utilizar el mismo
                correo electrónico y/o el mismo número telefónico registrado en
                su cuenta. En un breve intervalo de tiempo toda la información
                personal del cliente será eliminada del registro de aVenezuela.
              </li>
            </OrderedAlphaList>
            <p>
              Al utilizar nuestros servicios y aceptar nuestros Términos y
              Condiciones, el cliente acepta esta Política de Privacidad.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
